import React, { Fragment, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory, useLocation } from 'react-router-dom';

import Logo from '../../assets/images/logo.png';

import { useAuth } from '../../hooks';

import { Button, Menu } from '.';
import {
  AppBar,
  Container,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Route, UserType } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const [menuOptions, setMenuOptions] = useState([]);
  const classes = useStyles();
  const {
    clearAuthState,
    state: { isAuthenticated, user },
  } = useAuth();

  useEffect(() => {
    const dynamicMenuOpts = [{ label: 'Logout', onClick: clearAuthState }];
    setMenuOptions(dynamicMenuOpts);
  }, [location]);

  return (
    <Fragment>
      <Box
        py={0.75}
        px={[1.25, 3]}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <img
          style={{ cursor: 'pointer' }}
          height={60}
          src={Logo}
          alt="MagicPin® Logo"
          onClick={() => history.replace(Route.Root)}
        />
        <Box display="flex">
          <Hidden smUp></Hidden>
          {isAuthenticated && (
            <Menu
              options={[{ label: 'Logout', onClick: clearAuthState }]}
              label={
                <Fragment>
                  <AccountCircleOutlinedIcon />
                  &nbsp;
                  <Typography variant="body2">
                    {user.email || 'Unknown'}
                  </Typography>
                </Fragment>
              }
            />
          )}
        </Box>
      </Box>
    </Fragment>
  );
};
