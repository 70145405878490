import React from 'react';
import MuiCard from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const Card = ({
  children,
  title,
  isTitleSmall,
  noPadding,
  bgcolor = '',
  ...props
}) => {
  return (
    <MuiCard {...props}>
      {title && (
        <Box py={1.75} px={3} bgcolor="common.darkGrey" color="common.white">
          <Typography py={1} variant={isTitleSmall ? 'h4' : 'h3'}>
            {title}
          </Typography>
        </Box>
      )}
      <Box py={noPadding ? 0 : 1.75} px={noPadding ? 0 : 3} bgcolor={bgcolor}>
        {children}
      </Box>
    </MuiCard>
  );
};
