import React, { forwardRef } from 'react';
import TablePagination from '@material-ui/core/TablePagination';

import { Card } from './Card';
import MaterialTable from 'material-table';
import { Theme } from '../../constants/theme';
import { TABLE_ICONS } from '../../constants';
import { useTheme } from '@material-ui/core/styles';
import { Box, IconButton, Button } from '@material-ui/core';
import {
  FirstPageOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPageOutlined,
} from '@material-ui/icons';

export const MTable = ({
  columns,
  rowsPerPage = 0,
  rows,
  totalRows,
  currentPage,
  isLoading,
  onSort,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    const handleSpecificPageButtonClick = (event, page) => {
      onChangePage(event, page);
    };

    const paginationNumber = () => {
      const rangePage = 3;
      //Added the static first page
      let pages = [
        {
          page: 1,
          current: 1 === page + 1,
          clickable: true,
        },
      ];

      //If current page is 2 pages greater than one, we add '...' signal
      if (page > 2) {
        pages.push({
          page: '...',
          current: false,
          clickable: false,
        });
      }

      for (var i = page; i <= page + rangePage; i++) {
        if (i <= 1 || i > Math.ceil(count / rowsPerPage) - 1) {
          continue;
        }

        const obj = {
          page: i,
          current: i === page + 1,
          clickable: true,
        };

        pages.push(obj);
      }

      //If current page is 2 pages lower than the last one, we add '...' signal
      if (page + rangePage < Math.ceil(count / rowsPerPage) - 1) {
        pages.push({
          page: '...',
          current: false,
          clickable: false,
        });
      }

      //Added the static last page
      if (Math.ceil(count / rowsPerPage) !== 1) {
        pages.push({
          page: Math.ceil(count / rowsPerPage),
          current: Math.ceil(count / rowsPerPage) === page + 1,
          clickable: true,
        });
      }

      return pages;
    };

    return (
      <Box style={{ width: '100%', display: 'inline-flex' }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? (
            <LastPageOutlined />
          ) : (
            <FirstPageOutlined />
          )}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        {count !== 0
          ? paginationNumber().map(function (item, i) {
              if (item.current) {
                return (
                  <Button
                    color="primary"
                    style={{ minWidth: '3px', fontSize: 15 }}
                  >
                    {item.page}
                  </Button>
                );
              }

              return (
                <Button
                  disabled={!item.clickable}
                  onClick={(e) =>
                    handleSpecificPageButtonClick(e, item.page - 1)
                  }
                  style={{ minWidth: '3px', fontSize: 12 }}
                >
                  {item.page}
                </Button>
              );
            })
          : ''}
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? (
            <FirstPageOutlined />
          ) : (
            <LastPageOutlined />
          )}
        </IconButton>
      </Box>
    );
  };

  const handleRequestChangePage = (event, page) => {
    onChangePage(page);
  };

  const handleRequestSort = (orderedColumnId, orderDirection) => {
    onSort(columns[orderedColumnId].field, orderDirection);
  };

  const handleRequestChangeRowsPerPage = (event) => {
    window.scrollTo(0, 0);
    onChangeRowsPerPage(event.target.value);
  };

  return (
    <Card style={{ overflowX: 'auto' }} noPadding>
      <MaterialTable
        style={{ tableLayout: 'fixed' }}
        size="small"
        icons={TABLE_ICONS}
        isLoading={isLoading}
        data={rows}
        columns={columns}
        onOrderChange={handleRequestSort}
        options={{
          search: false,
          thirdSortClick: false,
          sorting: true,
          showTitle: false,
          cellStyle: {
            fontSize: 15,
            borderColor: Theme.palette.divider,
            overflowWrap: 'anywhere',
          },
          headerStyle: {
            color: Theme.palette.common.white,
            backgroundColor: Theme.palette.primary.main,
            borderColor: Theme.palette.divider,
            fontSize: 18,
            fontWeight: 'bold',
            lineHeight: 2,
            padding: '6px 24px 6px 16px',
          },
        }}
        components={{
          Toolbar: (props) => {
            return <> </>;
          },
          Pagination: (props) => {
            return (
              <TablePagination
                style={{ display: 'flex', flexDirection: 'row-reverse' }}
                labelDisplayedRows={() => {
                  return ``;
                }}
                labelRowsPerPage={<span>Rows per page:</span>}
                component="div"
                rowsPerPageOptions={[]}
                rowsPerPage={rowsPerPage}
                count={totalRows}
                page={currentPage}
                ActionsComponent={TablePaginationActions}
                onChangePage={handleRequestChangePage}
                onChangeRowsPerPage={handleRequestChangeRowsPerPage}
              />
            );
          },
        }}
      ></MaterialTable>
    </Card>
  );
};
