import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { useAuth, useToast } from '.';
import { Route } from '../constants';
import { AxiosPublic } from '../utils';

export const useLogin = () => {
  const [isFetching, setFetching] = useState(false);
  const { openToast } = useToast();
  const { updateAuthState } = useAuth();
  const history = useHistory();

  const handleLogin = async ({ email, password }) => {
    try {
      setFetching(true);
      const res = await AxiosPublic.patch(`/login`, {
        email,
        password,
      });
      updateAuthState({
        user: { ...res, isAuthenticated: true },
      });
      history.replace(Route.Root);
    } catch (err) {
      openToast({
        status: 'error',
        message: err?.response?.data?.message || 'Login failed',
      });
    }
    setFetching(false);
  };

  const handleResetPassword = async (token, email, password) => {
    const res = await AxiosPublic.patch(`/resetPassword/${token}`, {
      email,
      password,
    });
    updateAuthState({
      user: { ...res, isAuthenticated: true },
    });
  };

  return {
    isFetching,
    handleLogin,
    handleResetPassword,
  };
};
