import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { useAuth, useToast } from '.';
import { Route } from '../constants';
import { AxiosPublic } from '../utils';

export const useForgotPassword = () => {
  const [isFetching, setFetching] = useState(false);
  const { openToast } = useToast();
  const [isEmailSuccessful, setEmailSuccessful] = useState(false);

  const handleForgotPassword = async ({ email }) => {
    try {
      setFetching(true);
      await AxiosPublic.post(`/forgotPassword`, {
        email,
      });
      setEmailSuccessful(true);
      openToast({
        status: 'success',
        message: 'Email sent successfully.',
      });
    } catch (err) {
      openToast({
        status: 'error',
        message: err?.response?.data?.message || 'Reset Password failed',
      });
    }
    setFetching(false);
  };

  return {
    isFetching,
    handleForgotPassword,
    isEmailSuccessful,
  };
};
