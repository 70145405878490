import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import { useTheme, withStyles } from '@material-ui/core/styles';

import { getOptionalURLParams } from '../../utils';

import { Card } from './Card';
import { Box, IconButton, Button } from '@material-ui/core';
import {
  FirstPageOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPageOutlined,
} from '@material-ui/icons';

export const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 2,
  },
  body: {
    fontSize: 15,
    borderColor: theme.palette.divider,
    overflowWrap: 'anywhere',
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    fontSize: 14,
    lineHeight: 20,
    height: 54,
  },
}))(TableRow);

export const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    color: `${theme.palette.common.white} !important`,
  },
  icon: {
    color: `${theme.palette.common.white} !important`,
    opacity: 0.15,
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      right: '-30px',
    },
  },
}))(TableSortLabel);

export const Table = ({
  columns,
  rowsPerPage,
  rows,
  totalRows,
  currentPage,
  isLoading,
  onSort,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const history = useHistory();
  const { orderBy = columns[0].field, order = 'asc' } =
    getOptionalURLParams(history);

  const handleRequestSort = (value) => (event) => {
    onSort(value, orderBy === value && order === 'asc' ? 'desc' : 'asc');
  };

  const handleRequestChangePage = (event, page) => {
    window.scrollTo(0, 0);
    onChangePage(page);
  };

  const handleRequestChangeRowsPerPage = (event) => {
    window.scrollTo(0, 0);
    onChangeRowsPerPage(event.target.value);
  };

  const renderLoader = () =>
    [...Array(rows.length || rowsPerPage || 10)].map((_, index) => (
      <TableRow key={index}>
        <TableCell
          style={{ border: 'none', paddingTop: 10, paddingBottom: 10 }}
          colSpan={columns.length}
        >
          <Skeleton height={40} animation="wave" />
        </TableCell>
      </TableRow>
    ));

  const renderNoResults = () => (
    <TableRow style={{ height: 100 }}>
      <StyledTableCell colSpan={columns.length} align="center">
        No results
      </StyledTableCell>
    </TableRow>
  );

  const renderHead = () => {
    return (
      <TableRow>
        {columns.map(({ field, title, width, sorting = true }) => (
          <StyledTableCell key={field} style={{ width }}>
            {!onSort || !title || !sorting ? (
              title
            ) : (
              <StyledTableSortLabel
                active={orderBy === field}
                direction={orderBy === field ? order : 'asc'}
                onClick={handleRequestSort(field)}
              >
                {title}
              </StyledTableSortLabel>
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    );
  };

  const renderBody = () => {
    return rows.map((row, index) => (
      <StyledTableRow key={index}>
        {Object.keys(row).map((key) => (
          <StyledTableCell key={key}>{row[key]}</StyledTableCell>
        ))}
      </StyledTableRow>
    ));
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    const handleSpecificPageButtonClick = (event, page) => {
      onChangePage(event, page);
    };

    const paginationNumber = () => {
      const rangePage = 3;
      //Added the static first page
      let pages = [
        {
          page: 1,
          current: 1 === page + 1,
          clickable: true,
        },
      ];

      //If current page is 2 pages greater than one, we add '...' signal
      if (page > 2) {
        pages.push({
          page: '...',
          current: false,
          clickable: false,
        });
      }

      for (var i = page; i <= page + rangePage; i++) {
        if (i <= 1 || i > Math.ceil(count / rowsPerPage) - 1) {
          continue;
        }

        const obj = {
          page: i,
          current: i === page + 1,
          clickable: true,
        };

        pages.push(obj);
      }

      //If current page is 2 pages lower than the last one, we add '...' signal
      if (page + rangePage < Math.ceil(count / rowsPerPage) - 1) {
        pages.push({
          page: '...',
          current: false,
          clickable: false,
        });
      }

      //Added the static last page
      if (Math.ceil(count / rowsPerPage) !== 1) {
        pages.push({
          page: Math.ceil(count / rowsPerPage),
          current: Math.ceil(count / rowsPerPage) === page + 1,
          clickable: true,
        });
      }

      return pages;
    };

    return (
      <Box style={{ width: '100%', display: 'inline-flex' }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? (
            <LastPageOutlined />
          ) : (
            <FirstPageOutlined />
          )}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        {count !== 0
          ? paginationNumber().map(function (item, i) {
              if (item.current) {
                return (
                  <Button
                    color="primary"
                    style={{ minWidth: '3px', fontSize: 15 }}
                  >
                    {item.page}
                  </Button>
                );
              }

              return (
                <Button
                  disabled={!item.clickable}
                  onClick={(e) =>
                    handleSpecificPageButtonClick(e, item.page - 1)
                  }
                  style={{ minWidth: '3px', fontSize: 12 }}
                >
                  {item.page}
                </Button>
              );
            })
          : ''}
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? (
            <FirstPageOutlined />
          ) : (
            <LastPageOutlined />
          )}
        </IconButton>
      </Box>
    );
  };

  return (
    <Card style={{ overflowX: 'auto' }} noPadding>
      <MuiTable style={{ tableLayout: 'fixed' }} size="small">
        <TableHead>{renderHead()}</TableHead>
        <TableBody>
          {isLoading
            ? renderLoader()
            : rows.length === 0
            ? renderNoResults()
            : renderBody()}
        </TableBody>
      </MuiTable>
      {!isLoading && rows.length > 0 && rowsPerPage > 0 && (
        <TablePagination
          style={{ display: 'flex', flexDirection: 'row-reverse' }}
          labelRowsPerPage={<span>Rows per page:</span>}
          labelDisplayedRows={() => {
            return ``;
          }}
          component="div"
          rowsPerPageOptions={onChangeRowsPerPage ? [25, 50] : []}
          rowsPerPage={rowsPerPage}
          count={totalRows}
          page={currentPage}
          onChangePage={handleRequestChangePage}
          onChangeRowsPerPage={handleRequestChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </Card>
  );
};
