import React, { Suspense, lazy, useEffect, useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AxiosPrivate } from '../utils';
import { Route as Routes } from '../constants';
import { useAuth, useToast } from '../hooks';

const ResetPassword = lazy(() => import('../pages/public/ResetPassword'));
const HomeLayout = lazy(() => import('../components/HomeLayout'));
const Login = lazy(() => import('../pages/public/Login'));
const AccountDeletion = lazy(() => import('../pages/public/AccountDeletion'));
const ForgotPassword = lazy(() => import('../pages/public/ForgotPassword'));

const PrivateGuard = ({ component: Component, loginRedirect, ...rest }) => {
  const { openToast } = useToast();
  const {
    clearAuthState,
    state: { isAuthenticated },
  } = useAuth();
  const [isFetching, setFetching] = useState(false);
  const [redirect, setRedirect] = useState('');
  const { updateAuthState, isUsingAccessToken } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        setFetching(true);

        // Valid JWT. Therefore they should see the private route.
        await AxiosPrivate.get();
      } catch (e) {
        // Invalid JWT. Therefore they should not see the private route.
        setRedirect(loginRedirect || Routes.Root);

        // If they were authenticated, we want to display an error and clear the authentication state.
        // This only gets hit when JWT fails AND they refresh they page, NOT when they click the logout
        // button.
        if (isAuthenticated) {
          openToast({
            status: 'warning',
            message: 'Session timed-out. Please login again',
          });
          clearAuthState();
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [isAuthenticated]);

  return isFetching ? (
    <LinearProgress />
  ) : (
    <Route
      {...rest}
      render={(props) =>
        redirect ? <Redirect to={redirect} /> : <Component {...props} />
      }
    />
  );
};

const PublicGuard = ({ component: Component, loginRedirect, ...rest }) => {
  const {
    clearAuthState,
    state: { isAuthenticated },
  } = useAuth();
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    console.log('Auth', isAuthenticated);
    if (isAuthenticated) {
      setRedirect(loginRedirect || Routes.Root);
    }
  }, [isAuthenticated]);

  return (
    <Route
      {...rest}
      render={(props) =>
        redirect ? <Redirect to={redirect} /> : <Component {...props} />
      }
    />
  );
};

const AllRoutes = () => (
  <Switch>
    <PublicGuard exact path={Routes.Login} component={Login} />
    <PublicGuard exact path={Routes.AccountDeletion} component={AccountDeletion} />
    <PublicGuard
      path={Routes.ResetPassword.staticRoute}
      component={ResetPassword}
    />
    <PublicGuard
      exact
      path={Routes.ForgotPassword}
      component={ForgotPassword}
    />
    <PrivateGuard
      loginRedirect={Routes.Login}
      path={Routes.Root}
      component={HomeLayout}
    />
    <Route render={() => <Redirect to={Routes.Root} />} />
  </Switch>
);

const AppRoutes = () => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <AllRoutes />
    </Suspense>
  );
};

export { AppRoutes as Routes };
