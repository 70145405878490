export const Route = Object.freeze({
  Root: '/home',
  Login: '/login',
  AccountDeletion: '/accountdeletion',
  ResetPassword: {
    staticRoute: `/resetpassword/:token`,
    dynamicRoute: (token) => `/resetpassword/${token}`,
  },
  ForgotPassword: '/forgotpassword',
});

export const HomeRoutes = Object.freeze({
  Users: `${Route.Root}/users`,
  Shipment: `${Route.Root}/shipment`,
  Moderation: `${Route.Root}/moderation`,
  Flagged: `${Route.Root}/moderation/flagged`,
  ApprovePins: `${Route.Root}/approvepins`,
  DuplicatePins: `${Route.Root}/duplicatepins`,
  Content: `${Route.Root}/content`,
  UserContent: `${Route.Root}/content/users`,
  SellerRatings: `${Route.Root}/content/seller`,
  Transactions: `${Route.Root}/content/transactions`,
  ContactInfo: `${Route.Root}/content/contact`,
  Advertisement: `${Route.Root}/advertisement`,
  FlaggedUserContent: {
    staticRoute: `${Route.Root}/moderation/flagged/:id`,
    dynamicRoute: (id) => `${Route.Root}/moderation/flagged/${id}`,
  },
  UserDetails: {
    staticRoute: `${Route.Root}/user/:id`,
    dynamicRoute: (id) => `${Route.Root}/user/${id}`,
  },
  AnsweredUsers: {
    staticRoute: `${Route.Root}/advertisement/users`,
    dynamicRoute: (id) => `${Route.Root}/advertisement/users?option=${id}`,
  },
});
