import { Box, Container, Grid } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDuplicatePinContentModule, useDuplicatePinsModule } from '../../../hooks';
import { REACT_APP_S3_ENDPOINT } from '../../../utils';
import {
  Button,
} from '../../../components/generic';

export default ({ reportContentId, reportContent }) => {
  const {
    isFetching,
    markAsComplete,
    deleteImage,
    deleteLoading,
  } = useDuplicatePinContentModule();

  const{
    showReportContent
  } = useDuplicatePinsModule();

  const handleDelete = useCallback(async (element)=>{
    const result = await deleteImage(element);
    if(result){
      showReportContent(reportContentId)
    }
  }, [reportContentId]);

  return (
    <Container maxWidth="md">
      <Box pt={2} pb={2}>
        <Box m={1} p={2}>
          <Grid container direction='column' spacing={2}>
            <Grid container item md={12}>
              {reportContent.map(element => {
                if (element.delete == false) {
                  return (<Grid item md={3} alignContent='center' alignItems='center'>
                    <img
                      src={`${REACT_APP_S3_ENDPOINT}/${element.imageId}.png`}
                      alt={'Pin Image'}
                      width="100%"
                    ></img>

                  </Grid>)
                }
              })}
            </Grid>
            <Grid container item md={12} spacing={4}>
              {reportContent.map(element => {
                if (element.delete == false) {
                  return (<Grid item md={3} alignContent='center' alignItems='center'>
                    {element.delete ? "Deleted" : (
                      <Button
                        color="secondary"
                        text="DELETE"
                        size="small"
                        type="button"
                        loading={deleteLoading[element.imageId] ?? false}
                        onClick={() => handleDelete(element)}
                      />
                    )}
                  </Grid>)
                }
              })}
            </Grid>

          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
