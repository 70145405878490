import React, { useState, createContext } from 'react';

const initialState = {
  isAuthenticated: false,
  userType: '',
  user: {},
};

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
  const dehydratedState = localStorage.getItem('auth') || null;
  const [state, setState] = useState(
    dehydratedState ? JSON.parse(dehydratedState) : initialState,
  );

  const updateAuthState = (payload) => {
    const data = { ...payload, isAuthenticated: true };
    localStorage.setItem('auth', JSON.stringify(data));
    setState(data);
  };

  const clearAuthState = () => {
    const data = { ...initialState, userType: state.userType };
    localStorage.setItem('auth', JSON.stringify(data));
    setState(data);
  };

  const contextValue = {
    updateAuthState,
    clearAuthState,
    state,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
