import React, {useState, useEffect} from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import {
  AxiosPrivate,
  AxiosPublic,
  dateToString,
  getGenericError,
  getOptionalURLParams, REACT_APP_API_URL, REACT_APP_REVERSE_IMAGE_URL, REACT_APP_S3_ENDPOINT,
  setOptionalURLParams,
} from '../utils';
import { useToast } from '.';
import {
  PinApprovalColumns,
  DEFAULT_ROWS_PER_PAGE,
} from '../constants';
import { useHistory } from 'react-router';
import {
  Button,
  IconButton,
} from '@material-ui/core';
import { useModal } from './modal';
import PinContent from '../pages/private/approvepins/PinContent';

import { Field } from 'formik';

import { InfoOutlined } from '@material-ui/icons';

export const useApprovePinsModule = () => {
  const history = useHistory();
  const columns = PinApprovalColumns;
  const [isFetching, setFetching] = useState(false);
  const [tableData, setTableData] = useState({
    columns,
    rows: [],
    pinContent: [],
    totalRows: 0,
    currentPage: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  });
  const { openToast } = useToast();
  const { openModal, closeModal } = useModal();
  const [checkedState, setCheckedState] = useState([]);

  const handleOnChange = (position) => {
    setCheckedState((prev) =>
      prev.map((item, index) => (index === position ? !item : item)),
    );
  };

  const popover = (pin) => (
      <Popover id="popover-positioned-left">
        <Popover.Body>
          <div>
            <img
                src={`${REACT_APP_API_URL}/api/v1${pin.image}`}
                alt={pin.title}
                width="110"
                height="120"
            ></img>
          </div>
        </Popover.Body>
      </Popover>
  );

  const parseURLParams = () => {
    const {
      page = 1,
      search,
      orderBy,
      order,
      limit = DEFAULT_ROWS_PER_PAGE,
    } = getOptionalURLParams(history);
    if (search) {
      try {
        search = decodeURIComponent(search);
      } catch {}
    }
    return {
      page,
      search,
      orderBy,
      order,
      limit,
    };
  };

  const fetchAllPinsForApproval = async () => {
    try {
      setFetching(true);
      const { page, search, orderBy, order, limit } = parseURLParams();
      const {
        count = 0,
        total = 0,
        result = [],
      } = await AxiosPrivate.get('/approval/pins', {
        params: { page, search, limit, orderBy, order },
      });

      setTableData((prev) => ({
        ...prev,
        totalRows: total,
        currentPage: page - 1,
        rowsPerPage: parseInt(limit),
        pinContent: result.map((newPinContent) => ({
          pinContent: newPinContent,
        })),
        rows: result.map((newPinContent) => ({
          checkbox: (
            <label>
              <Field
                type="checkbox"
                name="checked"
                value={newPinContent.id.toString()}
              />
            </label>
          ),
          title: newPinContent.pin.title,
          createdAt: dateToString(newPinContent.createdAt, true, 'DD MMM YYYY'),
          createdBy: newPinContent.pin.user.email,
          parkOrigin: newPinContent.pin.otherPinDetails.parkOrigin,
          view: (
            <Button
              color="primary"
              style={{ fontSize: 15 }}
              onClick={() => showPinContent(newPinContent)}
            >
              View{' '}
            </Button>
          ),
          image: (
            <>
              <OverlayTrigger
                key="left-start"
                placement="left-start"
                overlay={popover(newPinContent.pin)}
              >
                <IconButton color="primary">
                  <InfoOutlined />
                </IconButton>
              </OverlayTrigger>
            </>
          ),
        })),
      }));
      setFetching(false);
    } catch (error) {
      openToast({
        status: 'error',
        message: 'Error fetching pins for approval',
      });
      setFetching(false);
    }
  };

  const showPinContent = async (newPinContent) => {
    openModal({
      title: 'New Pin Content',
      description: <PinContent newPinContent={newPinContent} />,
      size: 'md',
      textAlign: 'left',
      negativeActionText: 'Close',
      negativeActionOnClick: () => closeModal(),
    });
  };

  useEffect(() => {
    (async () => {
      await fetchAllPinsForApproval();
    })();
  }, [history.location.search, checkedState]);

  return {
    onPagination: (page) => setOptionalURLParams(history, { page: page + 1 }),
    onSort: (orderBy, order) =>
      setOptionalURLParams(history, { orderBy, order, page: 1 }),
    isFetching,
    tableData,
    checkedState,
    setCheckedState,
    setTableData,
    onChangeRowsPerPage: (limit) =>
      setOptionalURLParams(history, { limit, page: 1 }),
  };
};

export const usePinContentModule = () => {
  const history = useHistory();
  const columns = PinApprovalColumns;
  const [isFetching, setFetching] = useState(false);
  const [tableData, setTableData] = useState({
    columns,
    rows: [],
    totalRows: 0,
    currentPage: 0,
  });
  const { openToast } = useToast();
  const [reverseImageURL, setReverseImageURL] = useState([]);
  const { openModal, closeModal } = useModal();

  const fetchInfoForNewPinContent = async (image) => {
    try {
      const blob = new Blob([new Buffer.from(image)]);
      const formData = new FormData();
      formData.append('image', blob, 'image001.png');
      const { result } = await AxiosPublic.patch(
        REACT_APP_REVERSE_IMAGE_URL,
        formData,
      );
      setReverseImageURL(
        result.map(
          ({ img_id }) => `${REACT_APP_S3_ENDPOINT}/${img_id}`,
        ),
      );
    } catch (error) {
      openToast({
        status: 'error',
        message: 'Error fetching reverse image search pins',
      });
    }
  };

  const approvePin = async (id, values) => {
    setFetching(true);
    try {
      await AxiosPrivate.patch(`/approval/${id}/approve`, values);
      openToast({
        status: 'success',
        message: 'Pin successfully added',
      });
      closeModal();
    } catch (error) {
      openToast({
        status: 'error',
        message: getGenericError(error) || 'Error approving pin',
      });
    }
    setFetching(false);
  };

  const approvePinBatch = async (values) => {
    setFetching(true);
    try {
      await AxiosPrivate.patch(`/approval/approve`, values);
      openToast({
        status: 'success',
        message: 'Pins successfully added',
      });
      closeModal();
    } catch (error) {
      openToast({
        status: 'error',
        message: getGenericError(error) || 'Error approving pin',
      });
    }
    setFetching(false);
  };

  const dismissPin = async (id) => {
    setFetching(true);
    try {
      await AxiosPrivate.patch(`/approval/${id}/dismiss`);
      openToast({
        status: 'success',
        message: 'Pin successfully dismissed',
      });
      closeModal();
    } catch (error) {
      openToast({
        status: 'error',
        message: getGenericError(error) || 'Error dismissing pin',
      });
    }
    setFetching(false);
  };

  const dismissPinBatch = async (values) => {
    setFetching(true);
    try {
      await AxiosPrivate.patch(`/approval/dismiss`, values);
      openToast({
        status: 'success',
        message: 'Pins successfully dismissed',
      });
      closeModal();
    } catch (error) {
      openToast({
        status: 'error',
        message: getGenericError(error) || 'Error dismissing pin',
      });
    }
    setFetching(false);
  };

  return {
    isFetching,
    fetchInfoForNewPinContent,
    reverseImageURL,
    approvePin,
    approvePinBatch,
    dismissPinBatch,
    dismissPin,
  };
};
