import React, {useState, useEffect} from 'react';
import {
  AxiosPrivate,
  dateToString,
  getGenericError,
  getOptionalURLParams, 
  setOptionalURLParams,
} from '../utils';
import { useToast } from '.';
import {
  DuplicatePinColumns,
  DEFAULT_ROWS_PER_PAGE,
} from '../constants';
import { useHistory } from 'react-router';
import {
  Button,
} from '@material-ui/core';
import { useModal } from './modal';


import DuplicatePinContent from "../pages/private/duplicatepins/DuplicatePinContent";

export const useDuplicatePinsModule = () => {
  const history = useHistory();
  const columns = DuplicatePinColumns;
  const [isFetching, setFetching] = useState(false);
  const [tableData, setTableData] = useState({
    columns,
    rows: [],
    totalRows: 0,
    currentPage: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  });
  const { openToast } = useToast();
  const { openModal, closeModal } = useModal();

  const parseURLParams = () => {
    const {
      page = 1,
      search,
      orderBy,
      order,
      limit = DEFAULT_ROWS_PER_PAGE,
    } = getOptionalURLParams(history);
    if (search) {
      try {
        search = decodeURIComponent(search);
      } catch {}
    }
    return {
      page,
      search,
      orderBy,
      order,
      limit,
    };
  };

  const fetchAllDuplicatePinReports = async () => {
    try {
      setFetching(true);
      const { page, search, orderBy, order, limit } = parseURLParams();
      const {
        count = 0,
        total = 0,
        result = [],
      } = await AxiosPrivate.get('/duplicate-pin/report', {
        params: { page, search, limit, orderBy, order },
      });

      setTableData((prev) => ({
        ...prev,
        totalRows: total,
        currentPage: page - 1,
        rowsPerPage: parseInt(limit),
        rows: result.map((reportContent) => ({
          id: reportContent.id.toString(),
          createdAt: dateToString(reportContent.createdAt, true, 'DD MMM YYYY'),
          createdBy: reportContent.user.email,
          view: reportContent.complete ? 'Completed' : (
            <Button
              color="primary"
              style={{ fontSize: 15 }}
              onClick={() => showReportContent(reportContent.id)}
            >
              View{' '}
            </Button>
          ),
        })),
      }));
      setFetching(false);
    } catch (error) {
      openToast({
        status: 'error',
        message: 'Error fetching duplicate pin reports',
      });
      setFetching(false);
    }
  };

  const markAsComplete = async (id) => {
    setFetching(true);
    try {
      await AxiosPrivate.patch(`/duplicate-pin/report/complete/${id}`);
      openToast({
        status: 'success',
        message: 'Report Completed',
      });
      closeModal();
    } catch (error) {
      openToast({
        status: 'error',
        message: getGenericError(error) || 'Error completing report',
      });
    }
    setFetching(false);
  };

  const showReportContent = async (reportContentId) => {
    try {
      const pins = await AxiosPrivate.get(`/duplicate-pin/pins/${reportContentId}`);
      openModal({
        title: 'Report Content',
        description: <DuplicatePinContent reportContent={pins} reportContentId={reportContentId} />,
        size: 'md',
        textAlign: 'left',
        negativeActionText: 'Close',
        negativeActionOnClick: () => closeModal(),
        positiveActionText: 'Mark As Completed',
        positiveActionOnClick: () => markAsComplete(reportContentId),
      });
    } catch (error) {
      openToast({
        status: 'error',
        message: getGenericError(error) || 'Error completing report',
      });
    }
    
  };

  useEffect(() => {
    (async () => {
      await fetchAllDuplicatePinReports();
    })();
  }, [history.location.search]);

  return {
    onPagination: (page) => setOptionalURLParams(history, { page: page + 1 }),
    onSort: (orderBy, order) =>
      setOptionalURLParams(history, { orderBy, order, page: 1 }),
    isFetching,
    tableData,
    setTableData,
    onChangeRowsPerPage: (limit) =>
      setOptionalURLParams(history, { limit, page: 1 }),
    showReportContent:showReportContent
  };
};

export const useDuplicatePinContentModule = () => {
  const [isFetching, setFetching] = useState(false);
  const { openToast } = useToast();
  const [deleteLoading, setDeleteLoading] = useState({});
  
  const deleteImage = async (reportContent) => {
    setDeleteLoading((prevState) => ({ ...prevState, [reportContent.id]: true }))
    try {
      await AxiosPrivate.patch(`/duplicate-pin/pins/delete/${reportContent.id}/${reportContent.imageId}`);
      openToast({
        status: 'success',
        message: 'Image deleted',
      });
      // closeModal();
      setDeleteLoading((prevState) => ({ ...prevState, [reportContent.id]: false }));
      return true;
    } catch (error) {
      openToast({
        status: 'error',
        message: getGenericError(error) || 'Error deleting image',
      });
      setDeleteLoading((prevState) => ({ ...prevState, [reportContent.id]: false }));
      return false;
    }
  };

  return {
    isFetching,
    deleteLoading,
    deleteImage
  };
};
