import { useContext } from 'react';

import { AuthContext } from '../providers';

export const useAuth = () => {
  const { updateAuthState, clearAuthState, state } = useContext(AuthContext);
  return {
    updateAuthState: (payload) => updateAuthState(payload),
    clearAuthState: () => clearAuthState(),
    state,
    isUsingAccessToken: state?.user?.accessToken,
  };
};
