import React, { Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import { Header } from './Header';
import { Container, Divider } from '@material-ui/core';

export const Page = ({
  hideHeader,
  hideFooter,
  centerContent,
  isLoading,
  children,
}) => {
  const content = isLoading ? <CircularProgress /> : children;
  return (
    <Fragment>
      <Container maxWidth="lg">
        {!hideHeader && (
          <>
            <Header /> <Divider />
          </>
        )}
        {!centerContent ? (
          content
        ) : (
          <Box
            minHeight="calc(100vh - 44px)"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {content}
          </Box>
        )}
      </Container>
    </Fragment>
  );
};
