import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';

import { InputFieldLabel, InputFieldError } from '../generic';

export const RenderTextField = ({
  field: { value, name, onChange, onBlur },
  form: { touched, errors, setFieldValue },
  label,
  placeholder,
  formatter = null,
  mask = '',
  maskPlaceholder = '',
  alwaysShowMask = true,
  ...props
}) => {
  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  const { t } = useTranslation();

  const handleChange = (e) => {
    if (!formatter || formatter(e.target.value) || !e.target.value) {
      onChange(e);
    }
  };

  const handleBlur = (e) => {
    if (!formatter) setFieldValue(name, e.target.value.trim());
    onBlur(e);
  };

  return (
    <Fragment>
      {label && <InputFieldLabel label={t(label)} />}
      <TextField
        name={name}
        variant="outlined"
        fullWidth
        error={touch && !!error}
        placeholder={t(placeholder)}
        value={value ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      />
      {touch && !!error && <InputFieldError error={error} />}
    </Fragment>
  );
};
