import { Box, Container, Divider, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { usePinContentModule } from '../../../hooks';
import {AxiosImagePublic, REACT_APP_API_URL} from '../../../utils';
import {
  Button,
  InputFieldLabel,
} from '../../../components/generic';
import { Field, Form, Formik } from 'formik';
import { RenderTextField } from '../../../components/fields';
import { ApprovePinValidationSchema } from '../../../validation';

export default ({ newPinContent }) => {
  const {
    isFetching,
    fetchInfoForNewPinContent,
    reverseImageURL,
    approvePin,
    dismissPin,
  } = usePinContentModule();

  const { id, pin } = newPinContent;
  const { otherPinDetails } = pin;

  const [isDisabled, setIsDisabled] = useState(true);

  /**
   * On page load, grab the ID from the url and perform a search query to find the matching form data.
   */
  useEffect(() => {
    AxiosImagePublic.get(pin.image)
        .then(async (res) => {
          await fetchInfoForNewPinContent(res);
        })
  }, [pin.image]);

  return (
    <Container maxWidth="md">
      <Box pt={2} pb={2}>
        <Box m={1} p={2}>
          <Formik
            initialValues={{
              name: pin.title,
              editionSize: otherPinDetails.editionSize ?? '',
              origin: otherPinDetails.parkOrigin ?? '',
              originalPrice: otherPinDetails.price ?? '',
              releaseDate: otherPinDetails.releaseDate ?? null,
              keywords:
                otherPinDetails.tags?.length > 0
                  ? otherPinDetails.tags.join(', ')
                  : '',
              description: otherPinDetails.description ?? '',
            }}
            onSubmit={(values) => approvePin(id, values)}
            validationSchema={ApprovePinValidationSchema}
          >
            <Form>
              <Grid container spacing={2}>
                <Grid container md={12}>
                  <Grid item md={4}>
                    <InputFieldLabel label="Added Pin" />
                    {pin?.image && (
                        <img
                            src={`${REACT_APP_API_URL}/api/v1${pin.image}`}
                            alt={pin.title}
                            width="150"
                            height="220"
                        ></img>
                    )}
                  </Grid>
                  <Grid item md={8}>
                    <InputFieldLabel label="Reverse image search" />
                    {reverseImageURL?.length > 0 ? (
                      reverseImageURL.map((url) => (
                        <img
                          src={url}
                          alt={pin.title}
                          width="150"
                          height="220"
                        />
                      ))
                    ) : (
                      <img
                        src={`${process.env.PUBLIC_URL}/static/loading.gif`}
                        alt="loading"
                        width="100"
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container md={12} spacing={2}>
                  <Grid item md={6}>
                    <Field
                      name="name"
                      label="Title"
                      component={RenderTextField}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Field
                      name="editionSize"
                      label="Edition Size"
                      component={RenderTextField}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Field
                      name="origin"
                      label="Park Origin"
                      component={RenderTextField}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Field
                      name="originalPrice"
                      label="Price"
                      component={RenderTextField}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Field
                      name="releaseDate"
                      label="Release Date"
                      component={RenderTextField}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Field
                      name="keywords"
                      label="Tags"
                      component={RenderTextField}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Field
                      name="description"
                      label="Description"
                      component={RenderTextField}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Divider />
                </Grid>
                <Grid container md={12} spacing={2} justify="flex-end">
                  <Grid item md={3}>
                    <Button
                      color="primary"
                      text="Edit"
                      size="small"
                      variant="outlined"
                      loading={isFetching}
                      onClick={() => setIsDisabled(false)}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Button
                      color="primary"
                      text="Approve"
                      size="medium"
                      type="submit"
                      loading={isFetching}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Button
                      color="primary"
                      text="Dismiss"
                      size="medium"
                      variant="outlined"
                      onClick={() => dismissPin(id)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Box>
      </Box>
    </Container>
  );
};
