export const FlaggedUsersColumns = [
  { title: 'Full Name', field: 'fullName', width: '36%' },
  { title: 'Email', field: 'email', width: '37%' },
  {
    title: 'Status',
    field: 'resolvedStatus',
    width: '5%',
  },
  { title: '', field: 'view', width: '13%' },
];

export const FlaggedPinColumns = [
  { title: 'Pin Name', field: 'title', width: '25%' },
  { title: 'Date Reported', field: 'dateReported', width: '25%' },
  { title: '#Reports', field: 'reportedByCount', width: '10%' },
  {
    title: 'Last Actioned',
    field: 'lastActioned',
    width: '25%',
    sorting: false,
  },
  { title: '', field: 'view', width: '15%' },
];

export const FlaggedConversationColumns = [
  { title: 'Conversation ID', field: 'conversationId', width: '25%' },
  { title: 'Date Reported', field: 'dateReported', width: '25%' },
  { title: 'Reporter', field: 'reporterName', width: '20%' },
  { title: '', field: 'view', width: '5%' },
  { title: '', field: 'solved', width: '35%' },
];

export const UserColumns = [
  { title: 'Created', field: 'createdAt', width: '15%' },
  { title: 'Name', field: 'fullName', width: '17%' },
  { title: 'Email', field: 'email', width: '23%' },
  { title: 'Valid till', field: 'expireAt', width: '19%', sorting: false },
  { title: 'Quickblox', field: 'chat', width: '15%' },
  { title: '#Board', field: 'board', width: '7%', sorting: false },
  { title: '#Pins', field: 'pin', width: '7%', sorting: false },
  { title: '', field: 'details', width: '11%' },
];

export const AdvertisementColumns = [
  { title: 'Ads', field: 'ads', width: '35%' },
  { title: 'Count', field: 'count', width: '30%' },
  { title: '', field: 'delete', width: '25%' },
  { title: '', field: 'details', width: '20%' },
];

export const PinApprovalColumns = [
  { title: '', field: 'checkbox', width: '10%' },
  { title: 'Pin Name', field: 'title', width: '20%' },
  { title: 'Added At', field: 'createdAt', width: '20%' },
  { title: 'Added By', field: 'createdBy', width: '20%' },
  { title: 'Park Origin', field: 'parkOrigin', width: '20%' },
  { title: '', field: 'view', width: '10%' },
  { title: '', field: 'image', width: '10%' },
];


export const DuplicatePinColumns = [
  { title: 'ID', field: 'id', width: '20%' },
  { title: 'Reported At', field: 'created_at', width: '20%' },
  { title: 'Reported By', field: 'createdBy', width: '20%' },
  { title: '', field: 'view', width: '10%' },
];

