import * as yup from 'yup';

export const ResetPasswordValidationSchema = yup.object().shape({
    email: yup
        .string()
        .required('Email is required')
        .email('Please use a valid email address')
        .max(100, 'Email must be less than 100 characters'),
    password: yup
        .string()
        .required('Password is required')
        .min(8, 'Password must be 8 characters in length')
        .max(25, 'Must be less than 25 characters'),
    confirmPassword: yup
        .string()
        .required('Confirm Password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
});