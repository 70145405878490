import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  AxiosPrivate,
  AxiosPublic,
  REACT_APP_SHOW_APPOINTMENT,
} from '../utils';
import { useToast } from '.';
import { useLogin } from './login';
import { Route as Routes } from '../constants';
import { useAuth } from './auth';
import { useHistory } from 'react-router';

export const useResetPassword = () => {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const { openToast } = useToast();
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const { handleResetPassword } = useLogin();
  const {
    clearAuthState,
    state: { isAuthenticated },
  } = useAuth();

  const fetchTokenInformation = async (token) => {
    try {
      if (isAuthenticated) {
        openToast({
          status: 'error',
          message: 'Kindly logout and continue with the link',
        });
        return;
      }
      setFormSubmitting(true);
      const res = await AxiosPublic.get(`/${token}`);
      setUser(res);
    } catch (error) {
      openToast({
        status: 'error',
        message: 'Failed to fetch information about the user',
      });
    }
    setFormSubmitting(false);
  };

  const onSubmit = async ({ email, password, confirmPassword }, token) => {
    setFormSubmitting(true);
    try {
      await handleResetPassword(token, email, password);
      setFormSubmitting(false);
      history.replace(Routes.Root);
    } catch (e) {
      openToast({ status: 'error', message: e.message || 'Login failed' });
      setFormSubmitting(false);
    }
  };
  return {
    isFormSubmitting,
    user,
    fetchTokenInformation,
    onSubmit,
  };
};
