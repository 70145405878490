import axios from 'axios';

export const REACT_APP_API_URL = 'https://api.magicpinapp.net'
export const REACT_APP_REVERSE_IMAGE_URL = 'https://image-processing-prod.magicpinapp.net/reverse_image'
export const REACT_APP_S3_ENDPOINT = 'https://magic-pin-images-prod.s3.us-east-2.amazonaws.com'

export const AxiosPublic = axios.create({
  baseURL: `${REACT_APP_API_URL}/api/v1/admin`,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});
export const AxiosImagePublic = axios.create({
  baseURL: `${REACT_APP_API_URL}/api/v1`,
  responseType: 'arraybuffer'
});

export const AxiosPrivate = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
  baseURL: `${REACT_APP_API_URL}/api/v1/admin`,
});

AxiosPublic.interceptors.response.use((response) => response?.data);
AxiosImagePublic.interceptors.response.use((response) => response?.data);
AxiosPrivate.interceptors.response.use((response) => response?.data);
AxiosPrivate.interceptors.request.use((config) => {
  const auth = JSON.parse(localStorage.getItem('auth'));

  if (auth?.user?.accessToken) {
    // Provide fallback to access token if present
    config.headers['Authorization'] = `Bearer ${auth.user.accessToken}`;
  }

  return config;
});

export const getGenericError = (error) => error?.response?.data?.message;
