import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Routes } from './routes';
import { Theme } from './constants/theme';
import { AuthProvider, ModalProvider, ToastProvider } from './providers';
import { ErrorBoundary, Modal, Toast } from './components/generic';

const App = () => (
  <ThemeProvider theme={Theme}>
    <BrowserRouter>
      <AuthProvider>
        <ModalProvider>
          <ToastProvider>
            <ErrorBoundary>
              <CssBaseline />
              <Toast />
              <Modal />
              <Routes />
            </ErrorBoundary>
          </ToastProvider>
        </ModalProvider>
      </AuthProvider>
    </BrowserRouter>
  </ThemeProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));
