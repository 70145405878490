import { useState } from "react";

import { useToast } from ".";
import { AxiosPublic } from "../utils";

export const useAccountDeletion = () => {
  const [isFetching, setFetching] = useState(false);
  const { openToast } = useToast();

  const handleAccountDeletion = async ({ email, password }) => {
    try {
      setFetching(true);
      const response = await AxiosPublic.post(`/account-deletion`, {
        email,
        password,
      });
      if (response.status === 'error') {
        openToast({
          status: 'error',
          message: response.message,
        });
      } else {
        openToast({
          status: 'success',
          message: 'Your account has requested to delete',
        });
      }

    } catch (err) {
      openToast({
        status: 'error',
        message: err?.response?.data?.message || 'Account deletion is failed',
      });
    }
    setFetching(false);
  };

  return {
    isFetching,
    handleAccountDeletion,
  };
};
