import * as yup from 'yup';

const validateDateString = (s) => !s || /^\d{4}\/\d{2}\/\d{2}$/.test(s);

export const ApprovePinValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Pin name is required')
    .max(200, 'Pin name must be less than 200 characters'),
  origin: yup.string().max(200, 'Origin must be less than 200 characters'),
  editionSize: yup
    .string()
    .max(200, 'Edition size must be less than 200 characters'),
  originalPrice: yup
    .string()
    .max(200, 'Original price must be less than 200 characters'),
  keywords: yup.string().max(200, 'Keywords must be less than 200 characters'),
  releaseDate: yup
    .string()
    .nullable()
    .default(null)
    .test(
      'is-date',
      'Not a valid date format - YYYY/MM/DD',
      validateDateString,
    ),
    description: yup
    .string()
    .nullable()
    .default(null)
    .max(200, 'Description must be less than 200 characters'),
});
